<template>
  <div :class="[isMobile ? 'mobile-about-news' : '', 'about-news']">
    <item-title v-if="!isMobile" :title="{name: '新闻动态', en_1: 'NEWS', en_2: 'CENTER'}" />
    <div class="news-content">
      <template v-if="isMobile">
          <section v-show="topData.length > 0" class="section-1">
            <div v-for="item in topData" :key="item.id" class="news-item">
              <div @click="jumpDetail(item.id)" class="cover">
                <img v-if="item.pic" :src="item.pic" alt="">
                <img v-else src="../../assets/img/common/default.jpg" alt="">
              </div>
              <div class="detail">
                <div @click="jumpDetail(item.id)" class="title">{{item.name}}</div>
                <div @click="jumpDetail(item.id)" class="time">{{item.disploy_at.substring(0, 10)}}</div>
                <div @click="jumpDetail(item.id)" class="desc">{{item.discribe}}</div>
                <a @click="jumpDetail(item.id)" href="javascript:;">查看详情>></a>
              </div>
            </div>
          </section>
      </template>
      <template v-else>
        <transition name="fadeIn" enter-active-class="fadeInUp">
          <section v-show="topData.length > 0" class="section-1">
            <div v-for="item in topData" :key="item.id" class="news-item">
              <div class="cover">
                <img v-if="item.pic" :src="item.pic" alt="">
                <img v-else src="../../assets/img/common/default.jpg" alt="">
              </div>
              <div class="detail">
                <div @click="jumpDetail(item.id)" class="title">{{item.name}}</div>
                <div class="time">{{item.disploy_at.substring(0, 10)}}</div>
                <div class="desc">{{item.discribe}}</div>
                <a @click="jumpDetail(item.id)" href="javascript:;">查看详情>></a>
              </div>
            </div>
          </section>
        </transition>
      </template>
      <transition name="fadeIn" enter-active-class="fadeInUp">
        <section class="section-2">
          <div v-for="item in newsData" :key="item.id" class="news-item">
            <div class="time">
              <div class="day">{{item.disploy_at ? item.disploy_at.substring(0, 7) : ''}}</div>
              <div class="full">{{item.disploy_at ? item.disploy_at.substring(8,10) : ''}}</div>
              
            </div>
            <div class="detail">
              <div @click="jumpDetail(item.id)" class="title">{{item.name}}</div>
              <div class="desc">{{item.discribe}}</div>
            </div>
          </div>
        </section>
        </transition>
        <pager class="page" :total="total" :page="params.page" :limit="params.limit" @current-change="handleCurrentChange"></pager>
        <!-- <el-pagination prev-text="上一页" next-text="下一页" layout="prev, pager, next" :total="total" :current-page="params2.page" :page-size="params2.limit" @current-change="handleCurrentChange">
      </el-pagination> -->
    </div>
  </div>
</template>
<script>
import ItemTitle from '@/components/aboutUs/itemTitle'
import { getNewsAndDutyData } from '@/api/aboutUs'
import Pager from '@/components/common/Pager.vue'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    ItemTitle,
    Pager
  },
  data () {
    return {
      isMobile: isMobile(),
      params: {
        page: 1,
        limit: 10,
        type: 1
      },
      total: 0,
      topData: [],
      newsData: []
    }
  },
  created () {
    this.getNewsData ()
  },
  watch:{
    $route(to, from){
      if (from.name != 'Detail' && to.name == 'news') {
        // console.log(1221222112245566, from.name)
        this.params.page = 1
        this.getNewsData()
        // console.log(this.getNewsData)
      }
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.params.page = val
      this.$emit('loading', false)
      this.getNewsData()
    },
    // 获取详情数据
    async getNewsData () {
      const res = await getNewsAndDutyData(this.params)
      this.total = res.total
      if (this.params.page == 1) {
        this.topData = res.records.splice(0, 3)
        this.newsData = res.records.splice(0, res.records.length)
      } else {
        this.topData = []
        this.newsData = res.records
      }
      document.body.scrollTop=document.documentElement.scrollTop = 0
      this.$emit('loading', false)
    },
    // 跳转详情页
    jumpDetail (id) {
      this.$emit('loading', true)
      this.$router.push({ path: '/aboutUs/detail?type=news&id=' + id })
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-about-news.about-news {
  font-size: 16px;
  margin-left: 0rem;
  .page {
    margin-top: 15px!important;
  }
  .section-2 {
    margin-top: 0;
    .news-item {
      height: 65px;
      height: auto;
      padding: 10px 0;
      display: flex;
      align-items: center;
      border-bottom: 1px dotted #B8B7B7;
      .time {
        text-align: center;
        .day {
          color: #333333;
          font-size: 12px;
          line-height: normal;
          
        }
        .full {
          color: #333333;
          font-size: 14px;
          font-weight: bold;
          
        }
      }
      .detail {
        flex: 1;
        margin-left: 10px;
        .title {
          font-size: 14px;
          
          line-height: normal;
          cursor: pointer;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;
          &:hover {
            text-decoration: underline;
            font-weight: bold;
          }
        }
        .desc {
          font-size: .16rem;
          color: #666666;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
          margin-top: .1rem;
        }
      }
    }
  }
  .section-1 {
    margin-top: 0;
    margin-bottom: 20px;
    .news-item {
      display: block;
      margin-bottom: 15px;
      
      &:hover {
        .detail {
          background-color: #f48f2d;
          .title {
            color: #ffffff;
            font-weight: bold;
          }
          .desc {
            color: #ffffff;
          }
          .time {
            color: #ffffff;
          }
          a {
            color: #ffffff;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      height: auto;
      .cover {
        height: 100%;
        width: 100%;
        background-color: #ccc;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .detail {
        box-sizing: border-box;
        padding: 15px;
        background-color: #FAFAFA;
        transition: all .3s;
        .title {
          font-size: 14px;
          color: #343434;
          // font-weight: bold;
          line-height: normal;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .time {
          font-size: 12px;
          color: #999999;
          margin-top: 5px;
        }
        .desc {
          font-size: 12px;
          color: #797979;
          line-height: 22px;
          height: auto;
          margin-top: .05rem;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
        }
        a {
          color: #797979;
          font-size: .14rem;
          display: inline-block;
          margin-top: .1rem;
          text-decoration: none;
        }
      }
    }
  }
}
.about-news {  
  margin-left: 0.6rem;
  .section-2 {
    margin-top: .6rem;
    .news-item {
      height: 1.16rem;
      height: auto;
      display: flex;
      align-items: center;
      border-bottom: 1px dotted #B8B7B7;
      padding: .2rem 0;
      .time {
        text-align: center;
        border: 1px solid #cccccc;
        .day {
          color: #333333;
          font-size: .16rem;
          line-height: normal;         
           background-color: #cccccc;
           padding: 0.05rem; 
        }
        .full {
          font-size: .3rem;
          color: #333333;
          font-weight: bold;  
        }
      }
      .detail {
        flex: 1;
        margin-left: .3rem;
        .title {
          font-size: .22rem;
          
          line-height: normal;
          cursor: pointer;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;
          &:hover {
            text-decoration: underline;
            font-weight: bold;
          }
        }
        .desc {
          font-size: .16rem;
          color: #666666;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
          margin-top: .1rem;
          text-align: justify;
        }
      }
    }
  }
  .section-1 {
    margin-top: .7rem;
    .news-item {
      display: flex;
      margin-bottom: .2rem;
      
      &:hover {
        .detail {
          background-color: #f48f2d;
          .title {
            color: #ffffff;
            font-weight: bold;
          }
          .desc {
            color: #ffffff;
          }
          .time {
            color: #ffffff;
          }
          a {
            color: #ffffff;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      height: 2rem;
      .cover {
        height: 100%;
        width: 3.2rem;
        background-color: #ccc;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .detail {
        flex: 1;
        box-sizing: border-box;
        padding: .15rem .4rem;
        background-color: #FAFAFA;
        transition: all .3s;
        .title {
          font-size: .22rem;
          color: #343434;
          // font-weight: bold;
          line-height: normal;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .time {
          font-size: .14rem;
          color: #999999;
          margin-top: .05rem;
        }
        .desc {
          font-size: .16rem;
          color: #797979;
          line-height: .24rem;
          height: .48rem;
          margin-top: .05rem;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
          text-align: justify;
        }
        a {
          color: #797979;
          font-size: .14rem;
          display: inline-block;
          margin-top: .1rem;
          text-decoration: none;
        }
      }
    }
  }
}
</style>